import React from 'react';

const BigArrowRightRedIcon = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="80.001"
    height="80"
    role="img"
    aria-hidden="true"
  >
    <path
      data-name="Subtraction 2"
      d="M40 80.001a39.882 39.882 0 1 1 15.57-3.143A39.741 39.741 0 0 1 40 80.001Zm-17.5-40.5v1h33.771l-9.254 9.144.7.713 10.489-10.357-10.48-10.356-.7.71 9.254 9.147Z"
      fill="currentColor"
      opacity=".8"
    />
  </svg>
);

export default BigArrowRightRedIcon;
